var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "components-nav" }, [
    _vm.isLexing
      ? _c("div", { staticClass: "logo-name" }, [_vm._v("旺博")])
      : _c("div", { staticClass: "logo-name" }, [_vm._v("九耳")]),
    _c(
      "div",
      { staticClass: "link-box" },
      _vm._l(_vm.links, function(item, index) {
        return _c("router-link", { key: index, attrs: { to: item.href } }, [
          _vm._v(_vm._s(item.text))
        ])
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }