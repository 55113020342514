(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.nav = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  var _default = {
    name: 'Nav',
    data: function data() {
      var vm = this;
      var uuid = vm.$localStorage.getItem('uuid');
      var fType = vm.$strTool.filterFactoryType(uuid);
      var links = [{
        text: '返厂维护',
        href: '/ryProbe/afterSale/list',
        active: true
      }, {
        text: '出厂校验',
        href: '/ryProbe/preSale/list'
      }];
      fType == 'lexing' && links.shift();
      return {
        isLexing: fType == 'lexing',
        links: links
      };
    },
    props: {
      msg: String
    }
  };
  _exports.default = _default;
});