require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.array.map");

require("core-js/modules/es.function.name");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/dialog/style", "vant/es/dialog", "vant/es/toast/style", "vant/es/toast", "@/utils/time", "@/components/nav"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/dialog/style"), require("vant/es/dialog"), require("vant/es/toast/style"), require("vant/es/toast"), require("@/utils/time"), require("@/components/nav"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.dialog, global.style, global.toast, global.time, global.nav);
    global.warningProbeList = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _dialog, _style2, _toast, _time, _nav) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _dialog = _interopRequireDefault(_dialog);
  _toast = _interopRequireDefault(_toast);
  _nav = _interopRequireDefault(_nav);
  var _default = {
    name: "RyWarningProbeList",
    components: {
      Nav: _nav.default
    },
    data: function data() {
      var ossHost = this.$config.base.ossHost;
      return {
        ossHost: ossHost,
        startTime: '',
        endTime: '',
        number: '',
        list: [],
        scrapProbList: [],
        data: {},
        isShowModal: false,
        formLabelWidth: '80px',
        pageOptions: {
          pageSizes: [10, 20, 50, 100],
          pageSize: 10,
          total: 0,
          pageNo: 1
        },
        historyPageOptions: {
          pageSizes: [10, 20, 50, 100],
          pageSize: 10,
          total: 0,
          pageNo: 1
        },
        loading: false,
        isShowSureModal: false,
        sureResponsibleModel: '',
        imgNum: '',
        isShowHistory: false,
        isShowImgModal: false,
        selectItem: {},
        tagList: [],
        checkTagId: ''
      };
    },
    created: function created() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      var uuid = vm.$localStorage.getItem('uuid');
      var fType = vm.$strTool.filterFactoryType(uuid);
      vm.name = name;

      if (vm.$config.base.fList.indexOf(fType) > -1) {
        (fType != 'yingwo' || !name) && vm.$router.replace({
          path: "/ryProbe/afterSale/login"
        });
      } else {
        vm.$router.replace({
          path: "404"
        });
      }

      vm.pageNo = 1;
      vm.historyPageNo = 1;
      vm.pageSize = 10;
      vm.historyPageSize = 10;
      vm.loadTag(true);
    },
    computed: {
      isShowSureBtn: function isShowSureBtn() {
        var vm = this;
        var USER_NAME = vm.$config.keys.USER_NAME;
        var name = vm.$localStorage.getItem(USER_NAME);
        return name == 'Yudada';
      }
    },
    methods: {
      loadTag: function loadTag(isResult) {
        var vm = this;
        var params = {};
        !isResult ? params.type = 6 : params.handlingType = 2;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL).concat(isResult ? 'fc/careInfo/tags' : 'returnFac/tags'),
          params: params
        }).then(function (res) {
          vm.tagList = [{
            id: '',
            tagName: "全部"
          }].concat(res.data);
          vm.loadCheckList();
          vm.loadCheckHistory();
        });
      },
      onConfirmSelect: function onConfirmSelect() {
        var vm = this;
        vm.isShowSureModal = false;

        if (!(vm.imgNum > 0)) {
          _toast.default.fail('请填写数量');

          return;
        }

        _dialog.default.confirm({
          title: "提示",
          message: "\u786E\u5B9A\u9A8C\u6536\u5417\uFF1F",
          confirmButtonColor: "#32BE32"
        }).then(function () {
          vm.$http({
            type: "POST",
            url: "".concat(vm.$config.base.PROBE_BASE_URL, "scrapProbe/check"),
            data: {
              size: vm.imgNum,
              password: vm.$config.base.password,
              tagId: vm.checkTagId
            }
          }).then(function (res) {
            if (res.code != 1) {
              _toast.default.fail(res.message || '验收失败');

              return;
            }

            _toast.default.success({
              message: '验收成功'
            });

            vm.imgNum = '';
            vm.checkTagId = '';
            vm.loadCheckList();
          }).catch(function (err) {
            vm.loading = false;
            console.log(err);
          });
        }).catch(function () {// on cancel
        });
      },
      onclickSelect: function onclickSelect(model) {
        this.sureResponsibleModel = model;
      },
      switchAccount: function switchAccount() {
        var vm = this;

        _dialog.default.confirm({
          title: "提示",
          message: "是否确认退出当前账号？",
          confirmButtonColor: "#32BE32"
        }).then(function () {
          var USER_NAME = vm.$config.keys.USER_NAME;
          vm.$localStorage.removeItem(USER_NAME);
          vm.$router.replace({
            path: "/ryProbe/afterSale/login"
          });
        }).catch(function () {// on cancel
        });
      },
      search: function search(type) {
        var vm = this;
        var err = '';

        if (vm.number.length < 5 && type != 1) {
          err = '请输入正确的ID';
        }

        if (type == 1 && vm.startTime && vm.endTime) {
          // !(vm.startTime && vm.endTime) && (err = '请选择开始跟结束时间')
          new Date(vm.endTime).getTime() < new Date(vm.startTime).getTime() && (err = '结束时间不能早于开始时间');
        }

        if (err) {
          _toast.default.fail({
            message: err
          });

          return;
        }

        vm.loadCheckList();
      },
      onChangePageNo: function onChangePageNo(pageNo) {
        this.pageOptions.pageNo = pageNo;
        this.loadCheckList();
      },
      onChangeHistoryPageNo: function onChangeHistoryPageNo(pageNo) {
        this.historyPageOptions.pageNo = pageNo;
        this.loadCheckHistory();
      },
      onChangeHistoryPageSize: function onChangeHistoryPageSize(pageSize) {
        this.historyPageOptions.pageSize = pageSize;
        this.loadCheckHistory();
      },
      onChangePageSize: function onChangePageSize(pageSize) {
        this.pageOptions.pageSize = pageSize;
        this.loadCheckList();
      },
      loadCheckList: function loadCheckList() {
        var vm = this;
        var params = {
          pageNo: vm.pageOptions.pageNo,
          pageSize: vm.pageOptions.pageSize
        };

        if (vm.startTime && vm.endTime) {
          params.startAt = new Date(vm.startTime).getTime() / 1000;
          params.endAt = new Date(vm.endTime).getTime() / 1000 + 3600 * 24 - 1;
        }

        vm.loading = true;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "scrapProbe/list"),
          params: params,
          except: true
        }).then(function (res) {
          if (!res.data) {
            (0, _toast.default)("网络错误");
            return;
          }

          vm.loading = false;
          res.data.data.map(function (item) {
            var tagItem = vm.tagList.filter(function (tItem) {
              return tItem.id == item.tagId;
            })[0];
            item.tagName = tagItem ? tagItem.tagName : '-';
            item.time = (0, _time.getYMD)(item.uploadAt);
          });
          vm.list = res.data.data;
          vm.pageOptions.total = res.data.total;
          vm.pageOptions.pageNo = vm.pageNo;
          vm.loadTotalData();
        });
      },
      loadTotalData: function loadTotalData() {
        var vm = this;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "scrapProbe/count")
        }).then(function (res) {
          if (!res.data) {
            (0, _toast.default)("网络错误");
            return;
          }

          res.data.map(function (item) {
            var tagItem = vm.tagList.filter(function (tItem) {
              return tItem.id == item.tagId;
            })[0];
            item.tagName = tagItem ? tagItem.tagName : '-';
          });
          vm.data = res.data;
        });
      },
      loadCheckHistory: function loadCheckHistory() {
        var vm = this;
        var params = {
          pageNo: vm.historyPageOptions.pageNo,
          pageSize: vm.historyPageOptions.pageSize
        };
        vm.loading = true;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "scrapProbe/check"),
          params: params
        }).then(function (res) {
          if (!res.data) {
            (0, _toast.default)("网络错误");
            return;
          }

          vm.loading = false;
          res.data.data.map(function (item) {
            var tagItem = vm.tagList.filter(function (tItem) {
              return tItem.id == item.tagId;
            })[0];
            item.tagName = tagItem ? tagItem.tagName : '-';
            item.time = (0, _time.getYMD)(item.createAt);
          });
          vm.scrapProbList = res.data.data;
          vm.historyPageOptions.total = res.data.total;
          vm.historyPageOptions.pageNo = vm.historyPageNo;
        });
      },
      // 全部确认
      onclickSureBtn: function onclickSureBtn() {
        this.sureResponsibleModel = '';
        this.isShowSureModal = true;
      },
      // 查看维护详情
      openImgModal: function openImgModal(row) {
        this.selectItem = row;
        this.isShowImgModal = true;
      },
      onCancel: function onCancel() {
        this.editItem = {};
        this.singleItem = {};
        this.singleItem = {};
        this.isShowModal = false;
      },
      onConfirm: function onConfirm() {
        var vm = this;
        vm.loading = true;
      },
      openImg: function openImg(url) {
        window.open(url, '_blank');
      }
    }
  };
  _exports.default = _default;
});