var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-warning-probe-list" },
    [
      _c("Nav"),
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "title" }, [_vm._v("返厂维护/异常探头记录")]),
        _c("div", { staticClass: "header-right" }, [
          _c("span", { staticClass: "btn", on: { click: _vm.switchAccount } }, [
            _vm._v("切换登录")
          ])
        ])
      ]),
      _c("div", { class: { content: true } }, [
        _c(
          "div",
          { staticClass: "filter-box" },
          [
            _c("el-row", { attrs: { gutter: 24 } }, [
              _c("div", { staticClass: "header-tab" }, [
                _c(
                  "div",
                  { staticClass: "check-count-list" },
                  _vm._l(_vm.data, function(item, index) {
                    return _c("div", { key: index, staticClass: "list-item" }, [
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v(_vm._s(item.tagName))
                      ]),
                      _c("span", [
                        _vm._v("总数：" + _vm._s(item.totalSize) + "个")
                      ]),
                      _c("span", [
                        _vm._v("已验收：" + _vm._s(item.checkedSize) + "个")
                      ]),
                      _c("span", [
                        _vm._v(
                          "剩余：" +
                            _vm._s(item.totalSize - item.checkedSize) +
                            "个"
                        )
                      ])
                    ])
                  }),
                  0
                ),
                _c("div", { staticClass: "right-btn" }, [
                  _c(
                    "div",
                    {
                      staticClass: "warning-probe-btn",
                      on: {
                        click: function($event) {
                          _vm.isShowHistory = true
                        }
                      }
                    },
                    [_vm._v("验收记录")]
                  )
                ])
              ])
            ]),
            _c(
              "el-row",
              { attrs: { gutter: 24 } },
              [
                _c(
                  "el-form",
                  { attrs: { "label-width": "90px", center: "" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 12, md: 14, lg: 14, xl: 14 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "上传时间:", "label-width": "72px" }
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "startTime" } },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        placeholder: "开始时间"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.search(1)
                                        }
                                      },
                                      model: {
                                        value: _vm.startTime,
                                        callback: function($$v) {
                                          _vm.startTime = $$v
                                        },
                                        expression: "startTime"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "line", attrs: { span: 1 } },
                              [_vm._v("-")]
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "endTime" } },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        placeholder: "结束时间"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.search(1)
                                        }
                                      },
                                      model: {
                                        value: _vm.endTime,
                                        callback: function($$v) {
                                          _vm.endTime = $$v
                                        },
                                        expression: "endTime"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-body" },
          [
            _c(
              "el-table",
              { staticStyle: { width: "100%" }, attrs: { data: _vm.list } },
              [
                _c("el-table-column", {
                  attrs: { label: "探头数量", prop: "size" }
                }),
                _c("el-table-column", {
                  attrs: { label: "故障类型", prop: "tagName" }
                }),
                _c("el-table-column", {
                  attrs: { label: "上传时间", prop: "time" }
                }),
                _c("el-table-column", {
                  attrs: { label: "查看" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.openImgModal(scope.row)
                                }
                              }
                            },
                            [_vm._v("查看 ")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _c("el-pagination", {
              attrs: {
                "page-no": _vm.pageOptions.pageNo,
                "page-sizes": _vm.pageOptions.pageSizes,
                "page-size": _vm.pageOptions.pageSize,
                center: "",
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.pageOptions.total
              },
              on: {
                "size-change": _vm.onChangePageSize,
                "current-change": _vm.onChangePageNo,
                "update:pageNo": function($event) {
                  return _vm.$set(_vm.pageOptions, "pageNo", $event)
                },
                "update:page-no": function($event) {
                  return _vm.$set(_vm.pageOptions, "pageNo", $event)
                }
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "footer" }, [
        _c("img", { attrs: { src: _vm.ossHost + "img/logo.png", alt: "" } })
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请输入此次验收的探头数量",
            visible: _vm.isShowSureModal,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowSureModal = $event
            },
            close: _vm.onCancel
          }
        },
        [
          _c(
            "el-form",
            { staticStyle: { width: "70%", margin: "0 auto" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "验收故障类型:", "label-width": "100px" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.checkTagId,
                        callback: function($$v) {
                          _vm.checkTagId = $$v
                        },
                        expression: "checkTagId"
                      }
                    },
                    _vm._l(_vm.tagList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label:
                            item.tagName == "全部" ? "请选择" : item.tagName,
                          value: item.id
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "请输入数量:", "label-width": "100px" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入数量", center: "" },
                    model: {
                      value: _vm.imgNum,
                      callback: function($$v) {
                        _vm.imgNum = $$v
                      },
                      expression: "imgNum"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading },
                  on: {
                    click: function($event) {
                      _vm.isShowSureModal = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: !(_vm.imgNum > 0 && _vm.checkTagId),
                    loading: _vm.loading
                  },
                  on: { click: _vm.onConfirmSelect }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "验收记录",
            visible: _vm.isShowHistory,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowHistory = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "table-body" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.scrapProbList }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "故障类型", prop: "tagName" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "数量", prop: "size" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "时间", prop: "time" }
                  })
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "page-no": _vm.historyPageOptions.pageNo,
                  "page-sizes": _vm.historyPageOptions.pageSizes,
                  "page-size": _vm.historyPageOptions.pageSize,
                  center: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.historyPageOptions.total
                },
                on: {
                  "size-change": _vm.onChangeHistoryPageSize,
                  "current-change": _vm.onChangeHistoryPageNo,
                  "update:pageNo": function($event) {
                    return _vm.$set(_vm.historyPageOptions, "pageNo", $event)
                  },
                  "update:page-no": function($event) {
                    return _vm.$set(_vm.historyPageOptions, "pageNo", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.isShowHistory = false
                    }
                  }
                },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.isShowImgModal,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowImgModal = $event
            }
          }
        },
        [
          _c("div", { staticClass: "img-modal-body" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("span", [_vm._v(_vm._s(_vm.selectItem.tagName))]),
              _vm._v(" " + _vm._s(_vm.selectItem.time))
            ]),
            _c(
              "div",
              { staticClass: "model-img-list" },
              _vm._l(_vm.selectItem.images, function(item, index) {
                return _c("img", {
                  key: index,
                  attrs: { src: item, alt: "" },
                  on: {
                    click: function($event) {
                      return _vm.openImg(item)
                    }
                  }
                })
              }),
              0
            )
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.isShowImgModal = false
                    }
                  }
                },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }